import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import {getAds, updateAd, deleteAd} from '../api/api';
import axios from 'axios';
import {Carousel} from 'react-responsive-carousel';
import "./AdEditComponent.css"
import {toast, ToastContainer} from "react-toastify";

const AdEditComponent: React.FC = () => {
    const [ads, setAds] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentAd, setCurrentAd] = useState<any>({
        id: 0,
        name: '',
        description: '',
        url: '',
        imageUrls: [],
    });
    const [newAdName, setNewAdName] = useState('');
    const [newDescription, setNewDescription] = useState('');
    const [newUrl, setNewUrl] = useState('');
    const [imageFiles, setImageFiles] = useState<File[]>([]);
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [carouselKey, setCarouselKey] = useState(0);
    const [charCount, setCharCount] = useState(0);
    const charLimit = 250;
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(1);
    const adsPerPage = 5;
    const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = useState(false);
    const [placeToDelete, setPlaceToDelete] = useState<number | null>(null);


    useEffect(() => {
        const fetchData = async () => {
            const adsData = await getAds();
            setAds(adsData);
        };

        fetchData();
    }, []);
    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    }
    const filteredAds = ads.filter(ad =>
        ad.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    const totalPages = Math.ceil(filteredAds.length / adsPerPage);

    const openModal = (ad: any) => {
        setCurrentAd(ad);
        setNewAdName(ad.name);
        setNewUrl(ad.url);
        setNewDescription(ad.description);
        setImageUrls(ad.imageUrls || []);
        setIsEditing(true);
        setModalIsOpen(true);
        setImageFiles([]);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (e.target.files) {
            const files = Array.from(e.target.files);
            setImageFiles(files);

            const formDataTosend = new FormData();
            files.forEach((file) => {
                formDataTosend.append('files', file);
            });

            let updatedImageUrls: string[] = [...currentAd.imageUrls];

            try {
                const uploadResponse = await axios.post('https://api-bc.turismourbano.com.br/proxyback/api/ads/upload-images-ads', formDataTosend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                updatedImageUrls = [...updatedImageUrls, ...uploadResponse.data.fileUrls];
            } catch (error) {
                console.error('Error uploading image:', error);
                return;
            }

            setCurrentAd({
                ...currentAd,
                imageUrls: updatedImageUrls
            });
            setImageUrls(updatedImageUrls);
            setCarouselKey(prevKey => prevKey + 1);
            setImageFiles([]);

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };
    const openDeleteConfirmationModal = (id: number) => {
        setPlaceToDelete(id);
        setDeleteConfirmationIsOpen(true);
    };
    const confirmDeletePlace = async () => {
        if (placeToDelete !== null) {
            await handleDelete(placeToDelete);
            closeDeleteConfirmationModal();
        }
    };
    const handleDeleteClick = (id: number) => {
        openDeleteConfirmationModal(id);
    };


    const closeDeleteConfirmationModal = () => {
        setPlaceToDelete(null);
        setDeleteConfirmationIsOpen(false);
    };

    const handleSave = async () => {
        const adData = {
            id: currentAd.id,
            name: newAdName,
            description: newDescription,
            url: newUrl,
            imageUrls: imageUrls,
        };

        const requiredFields: Array<
            { name: string; value: string | string[] | undefined; message: string; isArray?: boolean }
        > = [
            {name: 'name', value: newAdName, message: 'O campo "Nome" está vazio!'},
            {name: 'description', value: newDescription, message: 'O campo "Descrição" está vazio!'},
            {name: 'url', value: newUrl, message: 'O campo "Url" está vazio!'},
            {name: 'imageUrls', value: imageUrls, message: 'Nenhuma imagem inserida!', isArray: true}
        ];

        for (const field of requiredFields) {
            if (!field.value ||
                field.value === '' ||
                field.value === null ||
                field.value === ' ') {
                toast.error(field.message);
                return;
            } else {
                if (field.isArray && Array.isArray(field.value)) {
                    if (field.value.length === 0) {
                        toast.error(field.message);
                        return;
                    }
                }
            }
        }

        try {
            await updateAd(currentAd.id, adData);

            setAds((prevAds) =>
                prevAds.map((ad) => (ad.id === currentAd.id ? {...ad, ...adData} : ad))
            );

            setShowConfirmation(true);

            setTimeout(() => {
                setShowConfirmation(false);
                closeModal();
            }, 1000);
        } catch (error) {
            console.error('Error updating ad:', error);
        }
    };

    const handleDelete = async (id: number) => {
        try {
            await deleteAd(id);
            const updatedAds = await getAds();
            setAds(updatedAds);
        } catch (error) {
            console.error('Error deleting ad:', error);
        }
    };

    const handleDeleteImage = async () => {
        if (currentAd.imageUrls.length <= 1) {
            toast.error('Não é possível excluir a última imagem. Pelo menos uma imagem deve ser mantida.');
            return;
        }

        const imageUrl = currentAd.imageUrls[currentImageIndex];
        try {
            const response = await axios.delete(`https://bc.turismourbano.com.br/api/ads/delete-image-ads`, {
                params: {imageUrl}
            });

            if (response.status === 200) {
                const updatedImageUrls = currentAd.imageUrls.filter((url: string, index: number) => index !== currentImageIndex);
                setCurrentAd({...currentAd, imageUrls: updatedImageUrls});
                setImageUrls(updatedImageUrls);

                setCurrentImageIndex(prevIndex => (prevIndex >= updatedImageUrls.length ? updatedImageUrls.length - 1 : prevIndex));
                setCarouselKey(prevKey => prevKey + 1);

                if (updatedImageUrls.length === 0) {
                    setCurrentImageIndex(0);
                }

                const placeData = {
                    ...currentAd,
                    imageUrls: updatedImageUrls
                }

                await updateAd(currentAd.id, placeData);

                setAds(prevPlaces =>
                    prevPlaces.map(place =>
                        place.id === currentAd.id ? {...place, imageUrls: updatedImageUrls} : place
                    )
                );
            }
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const currentAds = filteredAds.slice((currentPage - 1) * adsPerPage, currentPage * adsPerPage);

    return (
        <div className="ad-edit-container">
            <h2>Anúncios Cadastrados</h2>
            <div className="search-container">
                <input
                    type="text"
                    id="place-search"
                    name="place-search"
                    placeholder="Pesquisar nome de locais..."
                    aria-label="Pesquisar nome de locais"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                />
            </div>
            <div className="ad-edit-category-list">
                <div className="ad-edit-category-item">
                    <ul className="ad-edit-component-list">
                        {currentAds.map(ad => (
                            <li className='me-5' key={ad.id}>
                                <ul className="fw-bold">
                                    <div className="text-start text-primary fs-8"
                                         style={{width: '15rem', height: 'auto'}}>
                                        {ad.name}
                                    </div>
                                </ul>
                                <div className="custom-scroll me-5 fs-8" style={{maxWidth: '100%'}}>
                                    <div className="text-nowrap text-start">
                                        {ad.description}
                                    </div>
                                </div>
                                <div className="place-component-buttons">
                                    <button className="place-edit-button" onClick={() => openModal(ad)}>Editar
                                        <svg viewBox="0 0 512 512">
                                            <path
                                                d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7
                                                89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18
                                                23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3
                                                8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7
                                                253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3
                                                6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0
                                                8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3
                                                67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6
                                                14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5
                                                0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2
                                                16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
                                        </svg>
                                    </button>
                                    <button className="place-delete-button" onClick={() => openDeleteConfirmationModal(ad.id)}>
                                        <svg className="place-delete-button-top" viewBox="0 0 39 7" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <line y1="5" x2="39" y2="5" stroke="white" stroke-width="4"></line>
                                            <line x1="12" y1="1.5" x2="26.0357" y2="1.5" stroke="white"
                                                  stroke-width="3"></line>
                                        </svg>
                                        <svg className="place-delete-button-bottom" viewBox="0 0 33 39"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <mask id="path-1-inside-1_8_19" fill="white">
                                                <path
                                                    d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z"
                                                ></path>
                                            </mask>
                                            <path
                                                d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278
                                                43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37
                                                0V35C37 39.4183 33.4183 43 29 43V35V0H37Z"
                                                fill="white"
                                                mask="url(#path-1-inside-1_8_19)"
                                            ></path>
                                            <path d="M12 6L12 29" stroke="white" stroke-width="4"></path>
                                            <path d="M21 6V29" stroke="white" stroke-width="4"></path>
                                        </svg>
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="pagination-controls d-flex justify-content-center align-items-center my-3">
                <button
                    className="btn btn-primary me-2"
                    onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                    disabled={currentPage === 1}
                >
                    Anterior
                </button>
                <span className="mx-2">Página {currentPage} de {totalPages}</span>
                <button
                    className="btn btn-primary ms-2"
                    onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                    disabled={currentPage === totalPages}
                >
                    Próxima
                </button>
            </div>
            <Modal
                className="ad-edit-modal"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Edit Ad Modal">
                <ToastContainer/>
                <h2>Editar Anúncio</h2>
                <div className="ad-edit-modal-content">
                    <div className="ad-edit-left-column">
                        <div className="ad-edit-name-input">
                            <label htmlFor="name">Nome</label>
                            <input
                                type="text"
                                value={newAdName}
                                onChange={(e) => setNewAdName(e.target.value)}
                                className="ad-edit-edit-name"
                            />
                        </div>
                        <div className="ad-edit-description-input">
                            <label htmlFor="description">Descrição</label>
                            <textarea
                                value={newDescription}
                                onChange={(e) => {
                                    setNewDescription(e.target.value);
                                    setCharCount(e.target.value.length);
                                }}
                                maxLength={charLimit}
                            />
                        </div>
                        <div>
                            <small>{charCount}/{charLimit} caracteres.</small>
                            {charCount === charLimit && (
                                <p className="text-danger"> Você atingiu o limite de {charLimit} caracteres.</p>
                            )}
                        </div>
                        <div className="ad-edit-url-input">
                            <label htmlFor="url">URL</label>
                            <input
                                type="url"
                                id="url"
                                name="url"
                                value={newUrl}
                                onChange={(e) => setNewUrl(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="ad-edit-right-column">
                        {imageUrls.length > 0 && (
                            <div className="ad-edit-carousel-container">
                                <Carousel
                                    showThumbs={false}
                                    selectedItem={currentImageIndex}
                                    className="ad-edit-carousel"
                                    onChange={(index) => setCurrentImageIndex(index)}
                                >
                                    {imageUrls.map((url: string, index: number) => (
                                        <div key={index}>
                                            <img src={`https://api-bc.turismourbano.com.br/api/ads/image/${url}`}
                                                 alt={`Image ${index + 1}`}/>
                                        </div>
                                    ))}
                                </Carousel>
                                <div className="ad-edit-manage-image-container">
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        multiple
                                        onChange={handleImageChange}
                                        style={{display: 'none'}}
                                    />
                                    <button
                                        className="ad-edit-open-file"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            fileInputRef.current?.click()
                                        }}>
                                        <span className="ad-edit-file-wrapper">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 71 67">
                                                <path
                                                    stroke-width="5"
                                                    stroke="black"
                                                    d="M41.7322 11.7678L42.4645 12.5H43.5H68.5V64.5H2.5V2.5H32.4645L41.7322 11.7678Z"
                                                ></path>
                                            </svg><span className="ad-edit-file-front"></span>
                                        </span>
                                        Carregar Imagens
                                    </button>
                                    <button className="ad-edit-delete-image-button" onClick={handleDeleteImage}>
                                        Excluir Imagem
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="ad-edit-modal-footer">
                    <button className="ad-save-button" onClick={handleSave}>
                        Salvar
                        <svg fill="white" viewBox="0 0 448 512" height="1em" className="arrow">
                            <path
                                d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5
                                32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4
                                393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                        </svg>
                    </button>
                    <button className="ad-edit-cancel-button" onClick={closeModal}>
                        Cancelar
                    </button>
                </div>
                {showConfirmation && (
                    <div className="ad-edit-confirmation-box">
                        Alterações salvas com sucesso!
                    </div>
                )}
            </Modal>
            <Modal
            className="delete-modal"
                isOpen={deleteConfirmationIsOpen}
                onRequestClose={closeDeleteConfirmationModal}
                contentLabel="Delete Confirmation Modal"
            >
                <div className='delete-confirmation-box'>
                    <div>
                        <h2>Confirmação de Exclusão</h2>
                        <p>Você tem certeza que deseja excluir este local?</p>
                    </div>
                    <div>
                        <button className='ConfirmDeleteButton' onClick={confirmDeletePlace}>Confirmar</button>
                        <button className='CancelDeleteButton' onClick={closeDeleteConfirmationModal}>Cancelar</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AdEditComponent;
