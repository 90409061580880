import React, {useState, useEffect, useRef} from 'react';
import {GoogleMap, Marker, useLoadScript, Autocomplete} from '@react-google-maps/api';
import {createPlace, getCategories} from '../api/api';
import './mapcomponent.css';
import axios from "axios";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const mapContainerStyle = {
    height: "60vh",
    width: "100%",
};

const center = {
    lat: -26.93180515955524,
    lng: -48.955084490863335,
};

const libraries = 'places';

const MapWithForm: React.FC = () => {
    const {isLoaded} = useLoadScript({
        googleMapsApiKey: 'AIzaSyCpKt4zmu0y3yg_tvkjxu5FQUVxz-wZq3I',
        libraries: [libraries],
    });

    const [selectedLocation, setSelectedLocation] = useState<google.maps.LatLng | null>(null);
    const [categories, setCategories] = useState<any[]>([]);
    const [charCount, setCharCount] = useState(0);
    const charLimit = 410;
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        categoryId: 0,
        latitude: 0,
        longitude: 0,
        placeId: '',
        imageUrls: [],
        value: 0
    });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [imageFile, setImageFile] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [placeAddress, setPlaceAddress] = useState('');
    const [formattedValue, setFormattedValue] = useState<string>('');
    const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [suggestions, setSuggestions] = useState<google.maps.places.PlaceResult[]>([]);
    const [mapCenter, setMapCenter] = useState(center);
    const suggestionsRef = useRef<HTMLUListElement>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {

        if (isLoaded && inputRef.current) {
            autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current!, {
                bounds: {
                    north: -26.877904,
                    south: -27.028572,
                    east: -49.105120,
                    west: -48.880565
                },
                strictBounds: true,
                types: ['geocode'],
                componentRestrictions: {country: 'br'},
            });

            const observer = new MutationObserver(() => {
                const autocompleteElement = document.querySelector('.pac-container');
                if (autocompleteElement) {
                    (autocompleteElement as HTMLElement).style.display = 'none';
                }
            });

            observer.observe(document.body, {childList: true, subtree: true});

            autocompleteRef.current.addListener('place_changed', () => {
                const place = autocompleteRef.current!.getPlace();

                if (place.geometry) {
                    const lat = place.geometry.location?.lat() || 0;
                    const lng = place.geometry.location?.lng() || 0;

                    setFormData(prev => ({
                        ...prev,
                        latitude: lat,
                        longitude: lng,
                    }));

                    setMapCenter({lat, lng});
                    setSelectedLocation(new google.maps.LatLng(lat, lng));
                }
            });

            return () => observer.disconnect();
        }

        const fetchCategories = async () => {
            const data = await getCategories();
            setCategories(data);
        };
        fetchCategories();

        const input = inputRef.current;
        const handleInput = () => handleSearchAddress();

        if (input) {
            input.addEventListener('input', handleInput);
        }
        return () => {
            if (input) {
                input.removeEventListener('input', handleInput);
            }
        };
    }, [isLoaded]);

    const fetchPlaceId = async (lat: number, lng: number) => {
        try {
            const response = await axios.get(`https://api-bc.turismourbano.com.br/proxy/api/place/nearbysearch/json`, {
                params: {
                    location: `${lat},${lng}`,
                    radius: 50,
                    type: 'establishment',
                    key: 'AIzaSyCpKt4zmu0y3yg_tvkjxu5FQUVxz-wZq3I',
                },
            });

            const data = response.data;

            if (data.results && data.results.length > 0) {
                console.log(data.results[1].place_id);
                return data.results[1].place_id;
            } else {
                console.error('No place found');
                return null;
            }
        } catch (error) {
            console.error('Failed to fetch place ID:', error);
            return null;
        }
    };

    const handleMapClick = async (event: google.maps.MapMouseEvent) => {
        const latLng = event.latLng;

        if (latLng) {
            setSelectedLocation(latLng);
            setFormData(prev => ({
                ...prev,
                latitude: latLng.lat(),
                longitude: latLng.lng(),
            }));

            const placeId = await fetchPlaceId(latLng.lat(), latLng.lng());
            if (placeId) {
                setFormData(prev => ({
                    ...prev,
                    placeId
                }));
            }
        }
    };

    const handleSearchAddress = async () => {
        if (!placeAddress) return;

        if (isLoaded && inputRef.current) {
            const service = new google.maps.places.PlacesService(document.createElement('div'));
            const query = inputRef.current.value;

            const request = {
                location: new google.maps.LatLng(-26.9302, -48.9572),
                radius: 10000,
                keyword: query,
            };

            service.nearbySearch(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    setSuggestions(results || []);
                } else {
                    console.error("Nearby search failed:", status);
                }
            })
        }

        try {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({address: placeAddress}, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    if (results && results.length > 0) {
                        const location = results[0].geometry.location;
                        setSelectedLocation(location);

                    } else {
                        console.error('Nenhum local encontrado!');
                    }
                } else {
                    console.error('Erro ao buscar endereço!', status);
                }
            });
        } catch (error) {
            console.error('Erro ao encontrar endereço', error);
        }
    };

    const handleSuggestionClick = async (place: google.maps.places.PlaceResult) => {
        if (inputRef.current && place.geometry && place.geometry.location) {
            inputRef.current.value = place.name || '';
            setSuggestions([]);

            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setFormData(prev => ({
                ...prev,
                latitude: lat,
                longitude: lng,
            }));

            setMapCenter({lat, lng});
            setSelectedLocation(new google.maps.LatLng(lat, lng));

            const placeId = await fetchPlaceId(lat, lng);
            if (placeId) {
                setFormData(prev => ({
                    ...prev,
                    placeId,
                }));
            }
        }
    };

    const formatCurrency = (value: string): string => {
        const numericValue = value.replace(/\D/g, '');

        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(parseFloat(numericValue) / 100);

        return formattedValue;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const {name, value} = e.target;

        if (name === 'Value') {
            const numericValue = parseFloat(value.replace(/\D/g, '')) / 100;
            setFormData(prev => ({
                ...prev,
                [name]: numericValue
            }));
            setFormattedValue(formatCurrency(value));
        } else {
            setCharCount(value.length);
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        if (e.target.files && e.target.files.length > 0) {
            const filesArray = Array.from(e.target.files);
            setImageFile(filesArray);

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setIsSubmitting(true);

        let imageUrls: string[] = [];

        if (imageFile.length > 0) {
            const formDataToSend = new FormData();

            imageFile.forEach((file) => {
                formDataToSend.append('files', file);
            });

            try {
                const uploadResponse = await axios.post(
                    'https://api-bc.turismourbano.com.br/api/places/upload-images',
                    formDataToSend,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                imageUrls = uploadResponse.data.fileUrls;

                setImageFile([]);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    console.error('Error uploading image:', error.response ? error.response.data : error.message);
                } else if (error instanceof Error) {
                    console.error('Error uploading image:', error.message);
                } else {
                    console.error('Unknown error ocurred:', error);
                }
            }
        }

        const requiredFields: Array<
            { name: string; value: string | number | string[]; message: string; isArray?: boolean }
        > = [
            {name: 'name', value: formData.name, message: 'O campo "Nome" está vazio!'},
            {name: 'description', value: formData.description, message: 'O campo "Descrição" está vazio!'},
            {name: 'categoryId', value: formData.categoryId, message: 'Selecione uma categoria!'},
            {name: 'latitude', value: formData.latitude, message: 'Localização não selecionada!'},
            {name: 'longitude', value: formData.longitude, message: 'Localização não selecionada!'},
            {name: 'value', value: formattedValue, message: 'O campo "Valor" está vazio!'},
            {name: 'imageUrls', value: imageUrls, message: 'Nenhuma imagem inserida!', isArray: true}
        ];

        for (const field of requiredFields) {
            if (!field.value ||
                field.value === 0 ||
                field.value === '' ||
                field.value === null ||
                field.value === 'R$ 0,00' ||
                field.value === ' ') {
                toast.error(field.message);
                setIsSubmitting(false);
                return;
            } else {
                if (field.isArray && Array.isArray(field.value)) {
                    if (field.value.length === 0) {
                        toast.error(field.message);
                        setIsSubmitting(false);
                        return;
                    }
                }
            }
        }

        try {
            if (selectedLocation) {
                await createPlace({
                    ...formData,
                    latitude: formData.latitude,
                    longitude: formData.longitude,
                    placeId: formData.placeId,
                    imageUrls: imageUrls,
                    value: formData.value
                });

                setShowConfirmation(true);

                setTimeout(() => {
                    setShowConfirmation(false);
                    window.location.reload();
                }, 1500);

                setFormData({
                    name: '',
                    description: '',
                    categoryId: 0,
                    latitude: 0,
                    longitude: 0,
                    placeId: '',
                    imageUrls: [],
                    value: 0
                });
                setSelectedLocation(null);
            }
        } catch (error) {
            console.error('Error creating place:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearchAddress();
        }
    };

    return isLoaded ? (
        <div className="map-form-section">
            <ToastContainer/>
            <div className="map-left-column">
                <div className="map-mapbox">
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        zoom={15}
                        center={mapCenter}
                        onClick={handleMapClick}
                    >
                        {selectedLocation && <Marker position={selectedLocation.toJSON()}/>}
                    </GoogleMap>
                </div>
                <div className="map-search-input">
                    <div className="map-search-bar">
                        <input
                            ref={inputRef}
                            type="text"
                            value={placeAddress}
                            onChange={(e) => setPlaceAddress(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder={`Pesquisar endereço...`}
                        />
                        {suggestions.length > 0 && (
                            <ul ref={suggestionsRef} className="map-autocomplete-dropdown">
                                {suggestions.map((suggestion, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleSuggestionClick(suggestion)}
                                        style={{cursor: 'pointer', padding: '8px'}}
                                    >
                                        <i className="material-icons">place</i>
                                        {suggestion.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <button onClick={handleSearchAddress}>Pesquisar</button>
                </div>
            </div>

            {isSubmitting && (
                <div className="place-confirmation-box">
                    Carregando...
                </div>
            )}
            {showConfirmation && (
                <div className="map-confirmation-box">
                    Local adicionado com sucesso!
                </div>
            )}
            <form onSubmit={handleSubmit} className="map-right-column">
                <label htmlFor="name">Local:</label>
                <div className="map-name-input">
                    <input
                        type="text"
                        placeholder="Digite o nome do local aqui..."
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </div>
                <label htmlFor="description">Descrição:</label>
                <div className="map-description-input">
                            <textarea
                                placeholder="Digite a descrição do local aqui..."
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                maxLength={charLimit}
                            />
                    <div>
                        <small>{charCount}/{charLimit} caracteres</small>
                        {charCount === charLimit && (
                            <p className="text-danger">Você atingiu o limite de {charLimit} caracteres.</p>
                        )}
                    </div>
                </div>
                <label htmlFor="categoryId">Categoria:</label>
                <div className="map-category-select-wrapper">
                    <div className="map-category-selected">
                        {categories.find(category => category.id === parseInt(String(formData.categoryId), 10))?.name || "Escolha uma categoria"}
                        <svg
                            xmlns="https://www.w3.org/2000/svg"
                            height="1em"
                            viewBox="0 0 512 512"
                            className="map-category-arrow"
                        >
                            <path
                                d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5
                                        12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6
                                        169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                            ></path>
                        </svg>
                    </div>
                    <div className="map-category-options">
                        <div title="Escolha uma categoria">
                            <input id="default" name="categoryId" type="radio" value=""
                                   checked={formData.categoryId === 0} onChange={handleChange}/>
                        </div>
                        {categories.map(category => (
                            <div title={category.name} key={category.id}>
                                <input
                                    id={`category-${category.id}`}
                                    name="categoryId"
                                    type="radio"
                                    value={category.id}
                                    checked={parseInt(String(formData.categoryId), 10) === category.id}
                                    onChange={handleChange}
                                />
                                <label className="map-category-option" htmlFor={`category-${category.id}`}
                                       data-txt={category.name}></label>
                            </div>
                        ))}
                    </div>
                </div>
                <input
                    type="text"
                    id="latitude"
                    name="latitude"
                    value={formData.latitude}
                    readOnly
                    style={{display: 'none'}}
                />
                <input
                    type="text"
                    id="longitude"
                    name="longitude"
                    value={formData.longitude}
                    readOnly
                    style={{display: 'none'}}
                />
                <label htmlFor="Value">Valor:</label>
                <div className="map-value-input">
                    <input
                        type="text"
                        id="Value"
                        name="Value"
                        placeholder="R$ 0,00"
                        value={formattedValue}
                        onChange={handleChange}

                    />
                </div>
                <label htmlFor="image">Imagens:</label>
                <input
                    ref={fileInputRef}
                    type="file"
                    multiple
                    onChange={handleImageChange}
                    style={{display: 'none'}}
                />
                <button
                    className="map-open-file"
                    onClick={(e) => {
                        e.preventDefault();
                        fileInputRef.current?.click()
                    }}>
                            <span className="map-file-wrapper">
                                <svg xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 71 67">
                                    <path
                                        stroke-width="5"
                                        stroke="black"
                                        d="M41.7322 11.7678L42.4645 12.5H43.5H68.5V64.5H2.5V2.5H32.4645L41.7322 11.7678Z"
                                    ></path>
                                </svg><span className="map-file-front"></span>
                            </span>
                    Carregar Imagens
                </button>
                <button className="map-register-button" disabled={isSubmitting}>
                    Cadastrar
                    <svg fill="white" viewBox="0 0 448 512" height="1em" className="arrow">
                        <path
                            d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5
                                32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4
                                393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                    </svg>
                </button>
            </form>
        </div>
    ) : (
        <div>Carregando...</div>
    );
};

export default MapWithForm;
